
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  ref,
} from "vue";
import Page from "../atoms/Page.vue";
import {
  GetMyProfileResponseMembership,
  GetMyUsage,
  GetMyPlan,
  MyApi,
  Profile,
} from "../../autogen";
import { useRouter, useRoute } from "vue-router";
import Instruction from "../atoms/Instruction.vue";
import { Instruction as InstrctionType } from "../../types";
import Moment from "moment";
import { download } from "../../helpers/dom-utils";
import UIkit from "uikit";
import InquiryForm from "../molecules/InquiryForm.vue";
import EvaluationForm from "../molecules/EvaluationForm.vue";
import { loadStripe } from "@stripe/stripe-js";
type AlertModalInfo = {
  actionName: string;
  messages: string[];
  onClick: Function;
};
type State = {
  disabled: boolean;
  profile: Profile | null;
  membership: GetMyProfileResponseMembership | null;
  translation_file: File | null;
  translating: boolean;
  translated_file: { name?: string; size?: number } | null;
  error: string | null;
  usage: GetMyUsage | null;
  evaluationFormKey: number;
  inquiryFormKey: number;
  downloading: boolean;
  count: number;
  instruction?: InstrctionType;
  alertModalInfo: AlertModalInfo;
  showPopup: boolean;
  appliedPlan?: GetMyPlan;
  plans: GetMyPlan[];
};
export default defineComponent({
  components: { Page, Instruction, InquiryForm, EvaluationForm },
  setup() {
    const myApi = new MyApi();
    const route = useRoute();
    const router = useRouter();
    const fileInput = ref<HTMLInputElement>();
    const evaluationModal = ref<HTMLElement>();
    const state: State = reactive({
      disabled: true,
      profile: null,
      membership: null,
      translation_file: null,
      translating: false,
      translated_file: null,
      error: null,
      usage: null,
      evaluationFormKey: 0,
      inquiryFormKey: 0,
      downloading: false,
      count: 1,
      alertModalInfo: {
        actionName: "",
        messages: [],
        onClick: () => {},
      },
      showPopup: false,
      plans: [],
    });

    async function updateState() {
      const response = await myApi.getMyProfile({});
      state.profile = response.data.profile;
      state.membership = response.data.membership;
      state.membership.contract_renewal_date = Moment(
        response.data.membership.contract_renewal_date
      ).format("YYYY年MM月DD日");
    }
    onBeforeMount(async () => {
      await updateState();
      const response = await myApi.getMyPlans({});
      updatePlans(response.data);

      const isPaymentSucceeded = route.query.success;
      if (isPaymentSucceeded) {
        state.instruction = {
          level: "info",
          message: `回数を追加購入しました`,
        };
      }
      router.replace({ query: undefined });
    });
    const onUploadFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files![0].size > 52428800) {
        fileInput.value!.value = "";
        setTimeout(() => {
          state.error = "翻訳ファイルのサイズの上限は50MBです";
        });
        return;
      }
      state.translation_file = target.files![0];
    };
    const onFileDrop = (event: DragEvent) => {
      if (state.membership!.quota === 0) return;
      const files = event.dataTransfer!.files;
      state.translation_file = files[0];
    };
    const onUploadFileCloseButtonClick = () => {
      fileInput.value!.value = "";
      state.translation_file = null;
      state.translating = false;
      state.translated_file = null;
      state.error = null;
    };
    let interval: number;
    const additionalPurchaseButtonClick = async (_plan: GetMyPlan) => {
      state.alertModalInfo = {
        actionName: "購入手続き",
        messages: ["追加購入する回数を入力してください"],
        onClick: () => additionalPurchaseConfirmed(_plan),
      };
      setTimeout(() => {
        if (alertModalRef.value) {
          UIkit.modal(alertModalRef.value).show();
        }
      });
    };
    // state.count を更新するための関数を追加
    const updatePurchaseCount = (event: Event) => {
      const target = event.target as HTMLInputElement;
      state.count = parseInt(target.value, 10);
    };
    const onUploadButtonClick = async () => {
      state.translating = true;
      state.error = null;
      state.usage = null;
      try {
        const uploadResponse = await myApi.uploadMyFile({
          file: state.translation_file!,
        });
        // const uploadResponse = { status: 200, data: { id: 0 } };
        if (uploadResponse.status === 200) {
          interval = window.setInterval(async () => {
            const usageResponse = await myApi.getMyUsage({
              usageId: uploadResponse.data.id!,
            });
            // 翻訳中じゃない
            if (usageResponse.data.status && usageResponse.data.status !== 202) {
              state.translating = false;
              clearInterval(interval);
              // エラー終了
              if (usageResponse.data.status !== 200 && usageResponse.data.status !== 201) {
                if (usageResponse.data.err_state == "1") {
                  state.error = '翻訳に失敗しました<br/>文字データが埋め込まれていない（文字データをコピー＆ペーストできない）PDF です。<br/>翻訳には文字認識（OCR）機能が必要です。<br/>スタンダートもしくはエンタープライズプランをご利用ください。';
                }
                else {
                  state.error = "翻訳に失敗しました";
                }
              }
              // 翻訳完了
              else {
                state.translated_file = {};
                state.usage = usageResponse.data;
              }
            }
          }, 1000);
        }
      } catch (err) {
        console.error(err);
        clearInterval(interval);
        state.translating = false;
        state.error = "翻訳に失敗しました";
      }
    };
    const onDownloadButtonClick = async () => {
      state.error = null;
      state.downloading = true;
      try {
        const response = await myApi.downloadMyFile(
          {
            usageId: state.usage!.id,
          },
          {
            responseType: "arraybuffer",
          }
        );
        await updateState();
        download(`${state.translation_file!.name}_翻訳済.docx`, response.data);
        document.body.onfocus = () => {
          if (evaluationModal.value) {
            state.evaluationFormKey = Date.now();
            UIkit.modal(evaluationModal.value).show();
          }
          document.body.onfocus = null;
        };
      } catch (err) {
        state.error = "ダウンロードに失敗しました";
      }
      state.downloading = false;
    };
    onBeforeUnmount(() => {
      if (interval) {
        clearInterval(interval);
      }
    });
    const onAlertClose = () => {
      state.error = null;
    };
    const onInquiryButtonClick = () => {
      state.inquiryFormKey = Date.now();
      UIkit.modal(inquiryModalRef.value!).show();
    };
    const inquiryModalRef = ref<HTMLElement>();
    const onInquirySubmitted = () => {
      if (inquiryModalRef.value) {
        UIkit.modal(inquiryModalRef.value).hide();
      }
    };
    const onSubmit = () => {
      if (evaluationModal.value) {
        UIkit.modal(evaluationModal.value).hide();
      }
    };
    const fileSize = (size?: number) => {
      return size !== undefined
        ? `${Math.round((size / 1024 / 1024) * 10) / 10}MB`
        : "-";
    };
    const fileName = (name: string) => name;
    // 追加購入ボタン用
    const showPopup = ref(false);

    async function updatePlans(plans: GetMyPlan[]) {
      state.plans = plans;
      state.plans.sort((p1, p2) => {
        if (p1.applied) return 1;
        if (p2.applied) return -1;
        return (p1.price || 0) < (p2.price || 0)
          ? 1
          : (p1.price || 0) > (p2.price || 0)
          ? -1
          : 0;
      });
      state.appliedPlan = plans.find((p) => p.applied);
    }
    const additionalPurchaseConfirmed = async (plan: GetMyPlan) => {
      // plan の型を適宜設定
      const profile = await myApi.getMyProfile({});
      const stripe = await loadStripe(plan.stripe!.pk);
      if (!stripe) {
        console.error("Stripe failed to load.");
        return;
      }
      stripe.redirectToCheckout({
        mode: "payment",
        lineItems: [
          {
            price: state.membership!.plan!.add_purchase_payment_code,
            quantity: state.count, // state.count を正しく参照
          },
        ],
        successUrl: `${location.origin}/?success=1`,
        cancelUrl: `${location.origin}/?cancel=1`,
        customerEmail: profile.data.profile.email,
      });
      if (alertModalRef.value) {
        UIkit.modal(alertModalRef.value).hide();
      }
    };
    const goToPlan = () => {
      router.push("/plan");
    };
    const alertModalRef = ref<HTMLElement>();
    return {
      fileInput,
      evaluationModal,
      state,
      onUploadFileChange,
      onUploadFileCloseButtonClick,
      onUploadButtonClick,
      onAlertClose,
      onFileDrop,
      onDownloadButtonClick,
      onInquiryButtonClick,
      onInquirySubmitted,
      inquiryModalRef,
      onSubmit,
      fileSize,
      fileName,
      showPopup,
      additionalPurchaseConfirmed,
      additionalPurchaseButtonClick,
      alertModalRef,
      goToPlan,
      updatePurchaseCount
    };
  },
});
